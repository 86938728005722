<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 mb-3">
                <p class="h1">
                    <button id="st-start" class="btn btn-outline-primary st-btn " @click="startTest()"
                            v-if="isShowBtn">开始
                    </button>
                    <button id="st-stop" class="btn btn-danger st-btn " @click="stopTest()" v-else>停止
                    </button>
                </p>
                <p class="lead">
                    当前地址: <span id="st-ip">{{ info.ip }}</span>
                </p>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 st-block">
                <h3>下载速率</h3>
                <p class="display-4 st-value"><span id="st-download">{{ info.download }}</span></p>
                <p class="lead">Mbit/s</p>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 st-block">
                <h3>上传速率</h3>
                <p class="display-4 st-value"><span id="st-upload">{{ info.upload }}</span></p>
                <p class="lead">Mbit/s</p>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 st-block">
                <h3>TCP延迟</h3>
                <p class="display-4 st-value"><span id="st-ping">{{ info.ping }}</span></p>
                <p class="lead">ms</p>
            </div>
            <div class="col-lg-3 col-md-6 mb-3 st-block">
                <h3>延迟抖动</h3>
                <p class="display-4 st-value"><span id="st-jitter"> {{ info.jitter }}</span></p>
                <p class="lead">ms</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 mb-3">
                <p class="h5">
                    为提高网络测速的准确性，测试前请关闭其他应用，保持CPU处于空闲状态，多次测试取平均值。
                </p>

            </div>
        </div>
    </div>

</template>

<script>

export default {
    data() {
        return {
            code1: 'console.log("Hello, world!");',
            worker: null,
            interval: null,
            isShowBtn: true,
            info: {
                download: '0.00',
                upload: '0.00',
                ping: '0.00',
                jitter: '0.00',
                ip: "___.___.___.___",
            }
        }
    },
    methods: {
        startTest() {
            this.isShowBtn = false
            this.worker = new Worker('/api/speedtest_worker?r=' + Math.random())
            if (this.worker) {
                this.interval = setInterval(() => {
                    this.worker.postMessage('status')
                }, 100)
                let that = this;
                this.worker.onmessage = function (event) {
                    let data = event.data.split(';')
                    let status = Number(data[0])
                    if (status >= 4) {
                        clearInterval(that.interval)
                        that.isShowBtn = !that.isShowBtn
                        let w = null
                    }
                    if (status === 5) {
                        // speedtest cancelled, clear output data
                        data = []
                    }

                    that.info.download = (status === 1 && data[1] === 0) ? "即将开始" : data[1]
                    that.info.upload = (status === 3 && data[2] === 0) ? "即将开始" : data[2]
                    that.info.ping = data[3]
                    that.info.ip = data[4]
                    that.info.jitter = data[5] ? data[5] : "0.00"
                }
                this.worker.postMessage('start')
            }
        },
        stopTest() {
            if (this.worker) {
                this.isShowBtn = !this.isShowBtn
                this.worker.postMessage('abort')
            }
        }
    }
}
</script>


<style lang="less" scoped>
#st-start, #st-stop {
  //display: inline-block;
  margin: 0 auto;
  color: #6060AA;
  background-color: rgba(0, 0, 0, 0);
  border: 0.15em solid #6060FF;
  border-radius: 0.3em;
  transition: all 0.3s;
  box-sizing: border-box;
  width: 8em;
  height: 3em;
  line-height: 2.7em;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), inset 0 0 0 rgba(0, 0, 0, 0.1);
}


.container {
  max-width: 100%;
  width: 1140px;
}

.row {
  display: flex;
  margin-right: 15px;
  margin-left: 15px;
  flex-wrap: wrap;
  flex-direction: row;

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.st-block {
  text-align: center;
}

.st-btn {
  margin-top: -0.5rem;
  margin-left: 1.5rem;
}

.st-value > span:empty::before {
  content: "0.00";
  color: #636c72;
}

#st-ip:empty::before {
  content: "___.___.___.___";
  color: #636c72;
}

</style>

