<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="12">
        <el-input
                type="textarea"
                class="text-box text-box-input"
                placeholder="请输入URL"
                :rows="inputHeight"
                v-model="content"
                @input="getText">

        </el-input>
      </el-col>
      <el-col :span="13">
        <div class="code_content">
          <div>
            <pre v-highlightjs><code v-html="showResult"></code></pre>
          </div>
        </div>
      </el-col>
    </el-row>


  </div>
</template>

<script>
    import {urlParse} from "@/utils/tools";
    import hljs from "highlight.js";

    export default {
        data() {
            return {
                content: "",
                showResult: "",
                inputHeight: Math.floor(window.innerHeight / 21)
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                // 在窗口大小变化时触发的操作
                this.inputHeight = Math.floor(window.innerHeight / 22.5)
            },
            getText() {
                try {
                    if (this.content) {
                        console.log(this.content)
                        let obj = urlParse(this.content)
                        console.log(obj)
                        let result = `url = "${obj.url}"\n\nparams = ${obj.params}`
                        this.showResult = hljs.highlight(result, {language: 'python'}).value
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
</script>
