<template>
    <div id="app">
        <!--    <img alt="Vue logo" src="./assets/logo.png">-->
        <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里(主路由) -->
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
    // components: {
    //   HelloWorld
    // }
}
</script>

<style lang="less">
html, body, h3 p {
  margin: 0;
  padding: 0;

}

html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#app {
  height: 100%;
  width: 100%;
  /* transform-origin: left top; */
}
</style>
