import Vue from 'vue'
// import { Button, Row } from 'element-ui';  // 按需引入
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Vue.use(Button);  // 按需引入
// Vue.use(Row);  // 按需引入

import ElementUI from 'element-ui';
Vue.use(ElementUI);  // 全局引入


// import hljs from "highlight.js";
import "highlight.js/styles/base16/summerfruit-light.css";


import VueHighlightJS from 'vue-highlightjs'
Vue.directive('highlightjs', {
  componentUpdated: function (el) {
    let blocks = el.querySelectorAll('pre code');
    blocks.forEach((block) => {
      VueHighlightJS.highlightBlock(block);
    });
  },
});
Vue.use(VueHighlightJS)


import jsonView from 'vue-json-views'
Vue.use(jsonView)

import axios from 'axios'

// 创建实列
const instance = axios.create()
Vue.prototype.$axios = instance

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
