<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="12">
        <el-input
                type="textarea"
                class="text-box text-box-input"
                placeholder="请输入URL"
                :rows="inputHeight"
                v-model="content"
                @input="getText">

        </el-input>
      </el-col>
      <el-col :span="12">
                <el-input
                        type="textarea"
                        placeholder=""
                        class="text-box"
                        readonly
                        resize="none"
                        :rows="inputHeight"
                        v-model="showResult">

                </el-input>
<!--        <div class="class-file-box">-->
<!--          <el-button type="primary"-->
<!--                     icon="el-icon-document-copy"-->
<!--                     style="float: right;"-->
<!--                     v-if="showResult"-->
<!--                     @click="getCopy">复制-->
<!--          </el-button>-->
<!--          &lt;!&ndash; or literal code works as well &ndash;&gt;-->
<!--          <highlightjs language='javascript' code="var x = 5;" />-->
<!--        </div>-->
      </el-col>
    </el-row>


  </div>
</template>

<script>
    export default {
        data() {
            return {
                content: "",
                showResult: "",
                inputHeight: Math.floor(window.innerHeight / 21),
                aaa: '{"a": 1}'
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                // 在窗口大小变化时触发的操作
                this.inputHeight = Math.floor(window.innerHeight / 22.5)
            },
            getText() {
                try {
                    if (this.content) {
                        let text = this.content.trim()

                        this.showResult = decodeURIComponent(text)
                    }
                } catch (e) {
                    console.log(e)
                }
            },

            getCopy() {
                this.$copyText(this.showResult).then((e) => {
                    console.log('【复制成功】', e)
                    this.$message({
                        message: '复制成功',
                        type: 'success',
                        center: true
                    });

                }, function (e) {
                    console.log('【复制失败】', e)
                })

            },

        }
    }
</script>


<style>
    .class-file-box {
        text-align: center;
        /*width: 100px;*/
        height: 95vh;
        cursor: pointer;
        position: relative;
        line-height: 20px;
        margin-right: 30px;
        border-radius: 4px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* 阴影样式 */
        border: 1px solid #ccc; /* 边框样式 */
        padding: 10px; /* 可选的内边距样式，根据需要调整 */
        flex-wrap: wrap; /* 允许元素换行 */

    }
</style>
