<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="12">
        <el-input
                type="textarea"
                class="text-box text-box-input"
                placeholder="请输入URL"
                :rows="inputHeight"
                v-model="content"
                @input="getText">

        </el-input>
      </el-col>
      <el-col :span="12">
        <el-input
                type="textarea"
                placeholder=""
                class="text-box"
                readonly
                resize="none"
                :rows="inputHeight"
                v-model="showResult">

        </el-input>
      </el-col>
    </el-row>


  </div>
</template>

<script>
    export default {
        data() {
            return{
                content: "",
                showResult: "",
                inputHeight: Math.floor(window.innerHeight / 21)
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                // 在窗口大小变化时触发的操作
                this.inputHeight = Math.floor(window.innerHeight / 22.5)
            },
            getText(){
                try {
                    if (this.content) {
                        let text = this.content.trim()
                        let urlEncode = encodeURI(text)
                        let urlEncodeComponent = encodeURIComponent(text)
                        this.showResult = `普通转义:\n${urlEncode}\n\n完全转义:\n${urlEncodeComponent}`
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
</script>

