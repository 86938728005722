import Vue from 'vue'
import VueRouter from 'vue-router'

// 1. 定义路由组件
import format_json from "@/view/format_json.vue";
import format_header from "@/view/format_header.vue";
import main from "@/view/main.vue";
import format_cookie from "@/view/format_cookie.vue";
import url_decode from "@/view/url_decode";
import url_encode from "@/view/url_encode.vue";
import url_parse from "@/view/url_parse.vue";
import timeStamp from "@/view/timeStamp";
import speedTest from "@/view/speedTest";
import curl_to_request from "@/view/curl_to_request";
import crypto from "@/view/encryption";

Vue.use(VueRouter)


// 2. 将路由与组件映射
const routes = [
    // 主路由
    {
        path: "/",
        component: main,
        redirect: "/format_json",  // 当路由等于根路由时,进行重定向
        children: [
            // 子路由
            {path: "format_json", component: format_json},
            {path: "format_header", component: format_header},
            {path: "format_cookie", component: format_cookie},
            {path: "format_url", component: url_parse},
            {path: "url_encode", component: url_encode},
            {path: "timestamp", component: timeStamp},
            {path: "speed_test", component: speedTest},
            {path: "url_decode", component: url_decode},
            {path: "curl2Request", component: curl_to_request},
            {path: "crypto", component: crypto},
        ]
    }
]

// 3. 创建router实列
const router = new VueRouter({
    routes, // 相当于 routes: routes
    mode: "history"
})

export default router
// 4. 创建和挂载根实列


