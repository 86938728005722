<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="12">
        <el-input
                type="textarea"
                class="text-box text-box-input"
                placeholder="请输入Cookies"
                :rows="inputHeight"
                @input="getCookieText"
                v-model="content">

        </el-input>
      </el-col>
      <el-col :span="12">
        <div class="code_content">
          <div class="b-select">
            <el-button class="text-button operation_btn"
                       type="text"
                       icon="el-icon-document-copy" @click="getCopy">复制
            </el-button>
          </div>
          <json-view
                  :data="showResult"
                  :deep=2
                  theme="my-awesome-json-theme"
          >
          </json-view>
        </div>
      </el-col>
    </el-row>


  </div>
</template>

<script>
    import {cookiesFormat} from "@/utils/tools";

    import jsonView from 'vue-json-views'

    export default {
        components: {
            jsonView,
        },
        data() {
            return {
                content: '示例: \nname=value;expires=date;path=path;domain=domain;secure',
                showResult: {
                    "name": "value",
                    "expires": "date",
                    "path": "path",
                    "domain": "domain"
                },
                inputHeight: Math.floor(window.innerHeight / 21)
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                // 在窗口大小变化时触发的操作
                this.inputHeight = Math.floor(window.innerHeight / 22.5)
            },
            getCookieText() {
                try {
                    if (this.content) {
                        // this.showResult = JSON.stringify(result, null, 4)
                        this.showResult = cookiesFormat(this.content)
                    }
                } catch (e) {
                    this.$message({
                        message: e,
                        type: 'error',
                        center: true
                    });
                }
            },
            getCopy() {
                this.$copyText(JSON.stringify(this.showResult, null, 4)).then((e) => {
                    console.log('【复制成功】', e)
                    this.$message({
                        message: '复制成功',
                        type: 'success',
                        center: true
                    });

                }, function (e) {
                    console.log('【复制失败】', e)
                })
            },
        }
    }
</script>

<style scoped>
    @import "@/assets/button.css";
</style>
