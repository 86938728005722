<template>
  <div>
    <el-container>

      <el-aside width="auto">  <!-- 自适应 -->

        <common-aside/>
      </el-aside>
      <el-container>
        <!--                <el-header>-->
        <!--                    <common-header />-->
        <!--                </el-header>-->
        <el-main>

          <!-- 渲染子路由 -->
          <router-view></router-view>
        </el-main>
        <el-footer>
          <div class="contact">
<!--            <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 50px;">桂ICP备2023013190号-1</a>-->
            <a href="https://beian.miit.gov.cn/" target="_blank">桂ICP备2023013190号-1</a>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
    import commonAside from "@/components/commonAside.vue";
    import commonHeader from "@/components/commonHeader.vue";

    export default {
        data() {
            return {}
        },
        components: {
            commonAside,
            commonHeader
        }
    }
</script>

<style scoped>
    .el-header {
        padding: 0;
    }

    .contact {
        padding-top: 10px;
        text-align: center;
        /*position: absolute;*/
        line-height: 50px;
        bottom: 0;
        right: 0;
        left: 180px;
    }
    .contact a {
        color: rgba(44, 62, 80, .6196078431372549);
        text-decoration: none;
        font-size: 12px;
        font-family: 微软雅黑, monospace, Tahoma;

    }
    a:hover {
        color: red; /* 设置边框颜色为红色 */
    }
    /*.el-aside {*/
    /*    background-color: #D3DCE6;*/
    /*    color: #333;*/
    /*    text-align: center;*/
    /*    line-height: 200px;*/
    /*}*/

    .el-main {
        padding: 0!important;
    }

    /*body > .el-container {*/
    /*    margin-bottom: 40px;*/
    /*}*/

    /*.el-container:nth-child(5) .el-aside,*/
    /*.el-container:nth-child(6) .el-aside {*/
    /*    line-height: 260px;*/
    /*}*/

    /*.el-container:nth-child(7) .el-aside {*/
    /*    line-height: 320px;*/
    /*}*/
</style>
