<template>
  <div>
    <div>
      <span>
        <span>北京时间 </span>
        <span class="time">{{ formattedDate }}</span>
      </span>
      <span>
        <span>时间戳</span>
        <span>
          {{ secondTimestamp }}
          <span>秒</span>
        </span>
        <span>
          {{ millisecondTimestamp }}
          <span>毫秒</span>
        </span>
      </span>

    </div>
    <el-row :gutter="0">
      <el-col :span="12">
        <el-input
                type="textarea"
                class="text-box text-box-input"
                placeholder="示例:  -4074856110000"
                :rows="inputHeight"
                v-model="content"
                @input="getTimestampText">

        </el-input>
      </el-col>
      <el-col :span="12">
        <el-input
                type="textarea"
                placeholder="1840-11-15 15:37:13"
                class="text-box"
                readonly
                resize="none"
                :rows="inputHeight"
                v-model="showResult">

        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                content: "",
                showResult: "",
                inputHeight: Math.floor(window.innerHeight / 21),
                formattedDate: "",
                secondTimestamp: "",
                millisecondTimestamp: "",
                timerId: true,
            }
        },
        mounted() {
            setInterval(() => {
                this.getTime()
            }, 1000)

            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                // 在窗口大小变化时触发的操作
                this.inputHeight = Math.floor(window.innerHeight / 22.5)
            },

            getTimestampText() {
                try {
                    if (this.content) {
                        let date = this.isValidDateTime(this.content.trim())
                        if (date) {
                            this.showResult = date.toDate().getTime()
                        } else {
                            this.content = this.content.trim()
                            const timestamp = parseInt(this.content.length === 10 ? this.content + "000" : this.content)

                            let date = new Date(timestamp);
                            let year = date.getFullYear();
                            let month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
                            let day = date.getDate();
                            let hours = date.getHours();
                            let minutes = date.getMinutes();
                            let seconds = date.getSeconds();

                            this.showResult = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        }
                    }
                } catch (e) {
                    console.log(e)
                    this.showResult = e
                }
            },

            getTime() {
                // 获取当前时间
                let currentDate = new Date();
                // 获取日期的各个部分
                let year = currentDate.getFullYear();
                let month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以要加1，并且补零
                let day = ('0' + currentDate.getDate()).slice(-2); // 补零
                let hours = ('0' + currentDate.getHours()).slice(-2); // 补零
                let minutes = ('0' + currentDate.getMinutes()).slice(-2); // 补零
                let seconds = ('0' + currentDate.getSeconds()).slice(-2); // 补零

                // 将日期部分拼接成字符串
                this.formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
                this.secondTimestamp = currentDate.getTime()
                this.millisecondTimestamp = Math.floor(currentDate.getTime() / 1000)
            },

            isValidDateTime(str) {
                let dateTime = moment(str,
                    [
                        'YYYYMMDD',
                        'YYYY-MM-DD',
                        'YYYY/MM/DD',
                        'YYYYMMDD HH:mm:ss',
                        'YYYY-MM-DD HH:mm:ss',
                        'YYYY/MM/DD HH:mm:ss'
                    ], true);

                if (dateTime.isValid()) {
                    return dateTime
                } else {
                    return false
                }
            }
        }
    }
</script>

<style>
    .time {
        color: #12141d;
        display: inline-block;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        padding-left: 25px;
        position: relative;
        width: 180px;
    }

    .fa-stop {
        font: 80%/1.65 Verdana, "Geneva CE", lucida, 'Microsoft YaHei', sans-serif;
        text-indent: 0;
        color: rgb(231, 76, 60);
        margin-right: .1em;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
</style>

