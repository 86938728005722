<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="11">
        <el-input
                type="textarea"
                class="text-box text-box-input"
                placeholder="请输入Headers"
                :rows="inputHeight"
                v-model="content"
                @input="getHeaderText">

        </el-input>
      </el-col>
      <el-col :span="13">
        <div class="code_content">
          <div class="b-select">
            <el-button class="text-button operation_btn"
                       type="text"
                       icon="el-icon-document-copy" @click="getCopy">复制
            </el-button>
          </div>

          <json-view
                  :data="showResult"
                  :deep="2"
                  theme="my-awesome-json-theme"
          >
          </json-view>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

    import jsonView from 'vue-json-views'

    export default {
        components: {
            jsonView,
        },
        data() {
            return {
                content: `示例:
Accept-Encoding:gzip, deflate, br
Accept-Language:
zh-CN,zh;q=0.9,en;q=0.8
Cache-Control:
no-cache
Content-Length:496`,
                showResult: {
                    "Accept-Encoding": "gzip, deflate, br",
                    "Accept-Language": "zh-CN,zh;q=0.9,en;q=0.8",
                    "Cache-Control": "no-cache",
                    "Content-Length": "496"
                },
                result: "",
                inputHeight: Math.floor(window.innerHeight / 21)
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                // 在窗口大小变化时触发的操作
                this.inputHeight = Math.floor(window.innerHeight / 22.5)
            },
            getHeaderText() {
                try {
                    if (this.content) {
                        let lines = this.content.split('\n');
                        let jsonObject = {};
                        let step;
                        lines.forEach(function (line, index) {
                            if (!line.trim()) {
                                return;  // 跳过空行
                            }
                            if (step === index) {
                                return;
                            }
                            let parts = line.split(':');
                            let key = parts[0], value = parts.slice(1).join(':');

                            // 判断值是否为空或者下一行是否也是值的一部分
                            if (!value.trim() && index + 1 < lines.length) {
                                // 值占据多行，继续获取下一行作为值的一部分
                                value = lines[index + 1];
                                step = index + 1
                            } else {
                                step = index
                            }
                            jsonObject[key] = value.trim();
                        });
                        this.showResult = jsonObject
                        // this.result = JSON.stringify(jsonObject, null, 4)
                        // this.showResult = hljs.highlight(this.result, {language: 'json'}).value
                    }
                } catch (e) {
                    this.$message({
                        message: e,
                        type: 'error',
                        center: true
                    });
                }
            },

            getCopy() {
                this.$copyText(JSON.stringify(this.showResult, null, 4)).then((e) => {
                    console.log('【复制成功】', e)
                    this.$message({
                        message: '复制成功',
                        type: 'success',
                        center: true
                    });

                }, function (e) {
                    console.log('【复制失败】', e)
                })

            },
        }
    }
</script>

<style lang="less" scoped>

  @import "@/assets/button.css";
</style>

