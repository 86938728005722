<template>
  <el-menu default-active="1-4-1"
           class="el-menu-vertical-demo"
           @open="handleOpen"
           @close="handleClose"
           :collapse="isCollapse"
           text-color="rgb(44, 62, 80)"
           active-text-color="rgb(204, 0, 51)"
           style="font-weight: bold; line-height: 200px; border: 1px solid #dcdfe6;
    background-color: #fff;
    border-radius: 4px;"
  >
    <!--    <h3>{{ isCollapse ? '后台' : '小白工具库' }}</h3>-->
    <h3 style="font-weight: bold; color:rgb(44, 62, 80)">小白工具库</h3>
    <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
      <i :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
        <!--                <span slot="title">分组一</span>-->
        <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">{{ subItem.label }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
    export default {
        data() {
            return {
                menuDate: [
                    {
                        path: "/",
                        name: 'home',
                        label: 'JSON格式化',
                        // icon: 's-home',
                        url: 'json/json',
                    },
                    {
                        path: "/format_header",
                        name: 'mall',
                        label: 'Header格式化',
                        url: 'MallMange/MallMange',
                    },
                    {
                        path: "/format_cookie",
                        name: 'user',
                        label: 'Cookie格式化',
                        url: 'format_cookie/',
                    },


                    {
                        path: "/format_url",
                        name: 'URL格式化',
                        label: 'URL参数提取',
                        url: 'url_parse/',
                    },
                    {
                        path: "/url_encode",
                        name: 'url_encode',
                        label: 'URL编码',
                        url: 'url_encode/',
                    },
                    {
                        path: "/url_decode",
                        name: 'url_decode',
                        label: 'URL解码',
                        url: 'url_decode/',
                    },
                    {
                        path: "/timestamp",
                        name: 'timestamp',
                        label: '时间戳格式化',
                        url: 'timestamp/',
                    },
                    {
                        path: "/curl2Request",
                        name: 'curl',
                        label: 'CURL转request',
                        url: 'CURL/',
                    },
                    {
                        path: "/crypto",
                        name: 'crypto',
                        label: '加解密',
                        url: 'CURL/',
                    },
                    {
                        path: "/speed_test",
                        name: 'network',
                        label: '网络测速',
                        url: 'speedTest/',
                    }

                ]
            };
        },
        computed: {
            // 没有子菜单
            noChildren: function () {
                return this.menuDate.filter(item => !item.children)
            },
            // 有子菜单
            hasChildren() {
                return this.menuDate.filter(item => item.children)
            },
            isCollapse() {
                return this.$store.state.tab.isCollapse
            }
        },
        methods: {
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            // 点击菜单
            clickMenu(item) {
                // 容错 方法一
                // this.$router.push(item.path).catch((err) => {})
                // 方法二
                if (this.$route.path !== item.path && !(this.$route.path === "/format_json" && item.path === "/")) {
                    this.$router.push(item.path)
                }
            }
        },

    }
</script>

<style lang="less" scoped>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

  .el-menu {
    height: 100vh; // 撑满全屏
    border-right: none; // 可以取消底部滚动条
    h3 {
      color: #fff;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      font-weight: 400;
    }
  }
</style>
