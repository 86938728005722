<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="12">
        <div class="input-select">
          <el-select v-model="selectType" size="mini" placeholder="" class="operation_btn">
            <el-option
                    v-for="item in enTypes"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
          <el-button class="text-button operation_btn" type="text" icon="el-icon-d-caret"
                     @click="jsonClosed=!jsonClosed">折叠
          </el-button>

        </div>

        <el-input
                type="textarea"
                class="text-box text-box-input"
                placeholder="请输入Cookies"
                :rows="inputHeight"
                @input="getCookieText"
                v-model="content">

        </el-input>
      </el-col>
      <el-col :span="12">
        <div class="code_content">
          <div class="b-select">
            <el-button class="text-button operation_btn"
                       type="text"
                       icon="el-icon-document-copy" @click="getCopy">复制
            </el-button>
          </div>
          <json-view
                  :data="showResult"
                  :deep=2
                  theme="my-awesome-json-theme"
          >
          </json-view>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
    import {crypt, getEncrypt} from "@/utils/tools";

    import jsonView from 'vue-json-views'

    export default {
        components: {
            jsonView,
        },
        data() {
            return {
                content: null,
                showResult: {},
                inputHeight: Math.floor(window.innerHeight / 21),
                enTypes: [
                    {
                        value: "ALL",
                        label: "ALL"
                    },
                    {
                        value: "MD5",
                        label: "MD5"
                    },
                    {
                        value: "AES",
                        label: "AES"
                    },
                    {
                        value: "DES",
                        label: "DES"
                    },
                    {
                        value: "Base64",
                        label: "Base64"
                    },
                    {
                        value: "Latin1",
                        label: "Latin1"
                    },
                    {
                        value: "Hex",
                        label: "Hex"
                    },
                    {
                        value: "Utf8",
                        label: "Utf8"
                    },
                    {
                        value: "SHA1",
                        label: "SHA1"
                    },
                    {
                        value: "SHA256",
                        label: "SHA256"
                    },
                    {
                        value: "SHA512",
                        label: "SHA512"
                    },
                    {
                        value: "HmacMD5",
                        label: "HmacMD5"
                    },
                    {
                        value: "HmacSHA1",
                        label: "HmacSHA1"
                    },
                    {
                        value: "HmacSHA256",
                        label: "HmacSHA256"
                    },
                    {
                        value: "HmacSHA512",
                        label: "HmacSHA512"
                    },
                    {
                        value: "Rabbit",
                        label: "Rabbit"
                    },
                    {
                        value: "RC4",
                        label: "RC4"
                    },
                    {
                        value: "Utf16",
                        label: "Utf16"
                    },
                ],
                needPassword: new Set([
                    "AES", "DES", "TripleDES", "Rabbit", "RC4",
                    "RC4Drop", "HmacMD5", "HmacSHA1", "HmacSHA256",
                    "HmacSHA512"]),
                operationType: 1,
                selectType: "ALL",
                callObj: {
                    "ALL": getEncrypt,
                    "MD5": crypt.md5,
                    "Base64": crypt.base64,
                    "Latin1": crypt.Latin1,
                    "Hex": crypt.hex,
                    "Utf8": crypt.utf8,
                    "SHA1": crypt.sha1,
                    "SHA256": crypt,
                    "SHA512": crypt.sha512,
                    "HmacMD5": crypt.hmacMd5,
                    "HmacSHA1": crypt.hmacSha1,
                    "HmacSHA256": crypt.hmacSha256,
                    "HmacSHA512": crypt.hmacSha512,
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            handleResize() {
                // 在窗口大小变化时触发的操作
                this.inputHeight = Math.floor(window.innerHeight / 22.5)
            },
            getCookieText() {
                try {
                    if (this.content) {
                        this.showResult = this.callObj[this.selectType](this.content)
                    }
                } catch (e) {
                    this.$message({
                        message: e,
                        type: 'error',
                        center: true
                    });
                }
            },
            getCopy() {
                this.$copyText(JSON.stringify(this.showResult, null, 4)).then((e) => {
                    console.log('【复制成功】', e)
                    this.$message({
                        message: '复制成功',
                        type: 'success',
                        center: true
                    });

                }, function (e) {
                    console.log('【复制失败】', e)
                })
            },
        }
    }
</script>

<style lang="less">
  @import "@/assets/button.css";
  .input-select {
    position: absolute;
    left: 760px;
  }
</style>
