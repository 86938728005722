import Base64 from 'crypto-js/enc-base64'
import MD5 from 'crypto-js/md5';
import sha1 from 'crypto-js/sha1';
import sha3 from 'crypto-js/sha3';
import sha256 from 'crypto-js/sha256';
import sha384 from 'crypto-js/sha384';
import sha512 from 'crypto-js/sha512';
import hmacSha1 from 'crypto-js/hmac-sha1';
import hmacMd5 from 'crypto-js/hmac-md5';
import hmacSha256 from 'crypto-js/hmac-sha256';
import hmacSha512 from 'crypto-js/hmac-sha512';
import encHex from 'crypto-js/enc-hex';
import encUtf8 from 'crypto-js/enc-utf8';
import encUtf16 from 'crypto-js/enc-utf16';
import Latin1 from 'crypto-js/enc-latin1';
import rabbit from 'crypto-js/rabbit';
import rc4 from 'crypto-js/rc4';


export const crypt = {
    hex: {
        parse: function (utf8String) {
            return encHex.parse(utf8String)
        },
        stringify: function (plaintext) {
            return encHex.stringify(plaintext)
        },
    },
    Latin1: {
        parse: function (latin1Str) {
            return Latin1.parse(latin1Str)
        },
        stringify: function (wordArray) {
            return Latin1.stringify(wordArray)
        },
    },
    utf8: {
        parse: function (utf8String) {
            return encUtf8.parse(utf8String)
        },
        stringify: function (wordArray) {
            return encUtf8.stringify(wordArray)
        }
    },
    utf16: {
        parse: function (utf16String) {
            return encUtf16.parse(utf16String)
        },
        stringify: function (wordArray) {
            return encUtf16.stringify(wordArray)
        }
    },
    base64: {
        parse: function (plaintext) {
            return Base64.parse(plaintext)
        },
        stringify: function (plaintext) {
            return Base64.stringify(crypt.utf8.parse(plaintext))
        }
    },
    md5: function (plaintext) {
        return MD5(plaintext).toString()
    },
    sha1: function (plaintext) {
        return sha1(plaintext).toString()
    },
    sha256: function (plaintext) {
        return sha256(plaintext).toString()
    },
    sha3: function (plaintext) {
        return sha3(plaintext).toString()
    },
    sha384: function (plaintext) {
        return sha384(plaintext).toString()
    },
    sha512: function (plaintext) {
        return sha512(plaintext).toString()
    },
    hmacMd5: function (message, key = "") {
        return hmacMd5(message, key).toString()
    },
    hmacSha1: function (message, key = "") {
        return hmacSha1(message, key).toString()
    },
    hmacSha256: function (message, key = "") {
        return hmacSha256(message, key).toString()
    },
    hmacSha512: function (message, key = "") {
        return hmacSha512(message, key).toString()
    },
    Rabbit: {
        encrypt: function (message, key = "", cfg) {
            return rabbit.encrypt(message, key, cfg)
        },
        decrypt: function (ciphertext, key, cfg) {
            return rabbit.decrypt(ciphertext, key, cfg)
        }
    },
    RC4: {
        encrypt: function (message, key = "", cfg) {
            return rc4.encrypt(message, key, cfg)
        },
        decrypt: function (ciphertext, key, cfg) {
            return rc4.decrypt(ciphertext, key, cfg)
        }
    },
}

export function jsonStringify(data, space = 4) {

    return JSON.stringify(data, null, space)
}

export function cookiesFormat(content) {
    let cookies = {}
    let lines = content.split(';')
    lines.forEach(line => {
        if (line.includes("=")) {
            let [key, value] = line.split('=')
            cookies[key.trim()] = value.trim()
        }
    })

    return cookies
}

export function urlParse(url) {
    const parsedUrl = new URL(url.trim());
    const host = parsedUrl.origin;
    const path = parsedUrl.pathname;

    let params = {}
    if (parsedUrl.search) {
        let search = parsedUrl.search.replace("?", "")
        search = search.split('&')
        search.forEach(line => {

            let parts = line.split('=');
            let key = parts[0], value = parts.slice(1).join(':');

            params[key] = value.trim();
        });

    }

    return {url: host + path, params: jsonStringify(params)}
}

export function paramsParse(data) {
    let params = {}
    let pairs = data.split('&')
    pairs.forEach(line => {
        let [key, value] = line.split('=')
        params[key] = value
    })

    return params
}

export function curl2request(curl) {
    let content = curl.split('\n')
    let url;
    let headers = {};
    let cookies = {};
    let params = {};
    let data = {};
    let jsonData = {}
    content.forEach(line => {
        if (!line) {
            return
        }
        line = line.trim()
        if (line.includes("curl")) {
            url = /'(.*?)'/.exec(line)[1]
        } else if (line.includes("-H ")) {
            let h = /'(.*?)'/.exec(line)[1]

            if (line.includes("-H 'cookie")) {
                let value = h.split(": ")[1]
                cookies = cookiesFormat(value)
            } else {
                let [key, value] = h.split(": ")
                headers[key] = value
            }
        } else if (line.includes('--data-raw')) {
            let d = /'(.*?)'/.exec(line)[1]
            try {
                jsonData = JSON.parse(d)
                console.log(jsonData)
            } catch (e) {
                data = paramsParse(d)
            }
        }
    })
    return {
        url: url,
        headers: jsonStringify(headers),
        cookies: jsonStringify(cookies),
        params: jsonStringify(params),
        data: jsonStringify(data),
        jsonData: jsonStringify(jsonData)
    }

}


export function getEncrypt(plaintext) {
    const utf8 = crypt.utf8.parse(plaintext)

    return {
        "MD5": crypt.md5(plaintext),
        "AES": "U2FsdGVkX181ccJ5sF2sadfUyjoLObAttJhlo2P0jzM=",
        "DES": "U2FsdGVkX1+x3kQPGOIbJHR/imWqck7p",
        "Base64": crypt.base64.stringify(plaintext),
        "Latin1": crypt.Latin1.stringify(utf8),
        "Hex": crypt.hex.stringify(utf8),
        "Utf8": plaintext,
        "SHA1": crypt.sha1(plaintext),
        "SHA3": crypt.sha3(plaintext),
        "SHA256": crypt.sha256(plaintext),
        "SHA384": crypt.sha384(plaintext),
        "SHA512": crypt.sha512(plaintext),
        "HmacMD5": crypt.hmacMd5(plaintext),
        "HmacSHA1": crypt.hmacSha1(plaintext),
        "HmacSHA256": crypt.hmacSha256(plaintext),
        "HmacSHA512": crypt.hmacSha512(plaintext),
        "Rabbit": crypt.Rabbit.encrypt(plaintext).toString(),
        "RC4": crypt.RC4.encrypt(plaintext).toString(),
        "Utf16": crypt.utf16.stringify(utf8)
    }
}

export function getDecrypt(ciphertext) {
    return {
        "AES": "U2FsdGVkX181ccJ5sF2sadfUyjoLObAttJhlo2P0jzM=",
        "DES": "U2FsdGVkX1+x3kQPGOIbJHR/imWqck7p",
        "Base64": crypt.base64.parse(ciphertext),
        "Latin1": crypt.Latin1.parse(ciphertext),
        "Hex": crypt.hex.parse(ciphertext),
        "Utf8": ciphertext,
        "Rabbit": "",
        "RC4": "",
        "Utf16LE": crypt.utf16.parse(ciphertext)
    }
}
