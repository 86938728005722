<template>
    <div>
        <el-row :gutter="0">
            <el-col :span="11">
                <div>
                    <div class="content el-textarea el-input--medium">
                        <el-input
                                type="textarea"
                                class="text-box text-box-input"
                                :placeholder="tip"
                                :rows="40"
                                v-model="content"
                                @input="getText">
                        </el-input>
                    </div>
                </div>

            </el-col>
            <el-col :span="13">
                <div class="code_content">
                  <div class="b-select">
                    <el-button class="text-button operation_btn"
                               type="text"
                               icon="el-icon-document-copy" @click="getCopy">复制
                    </el-button>
                  </div>
                    <div>
                        <pre v-highlightjs><code v-html="showResult"></code></pre>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import hljs from "highlight.js";
import "highlight.js/styles/paraiso-light.css";
import {curl2request, urlParse} from "@/utils/tools";

export default {

    data() {
        return {
            content: "",
            showResult: 'import requests\n\n\nurl=\'https://www.baidu.com/s\'\nheaders = {\n    "Accept-Language": "zh-CN,zh;q=0.9,en;q=0.8",\n    "Cookie": "PSTM=1629808491"\n}\nparams = {\n    "wd": "feapder"\n}\nresponse = requests.get(url, headers=headers, cookies=cookies, params=params)\n\nprint(response.text)\nprint(response)',
            inputHeight: Math.floor(window.innerHeight / 21),
            tip: `输入curl，如\ncurl 'https://www.baidu.com/s?wd=feapder' \n-H 'Accept-Language: zh-CN,zh;q=0.9,en;q=0.8' \n-H 'Cookie: PSTM=1629808491; ' \n--compressed`,
            result: ""
        }
    },
    // mounted() {
    //     window.addEventListener('resize', this.handleResize);
    // },
    // destroyed() {
    //     window.removeEventListener('resize', this.handleResize);
    // },

    methods: {
        handleResize() {
            // 在窗口大小变化时触发的操作
            this.inputHeight = Math.floor(window.innerHeight / 22.5)
        },

        getText() {
            try {
                if (this.content) {
                    let obj = curl2request(this.content)
                    let urlObj = urlParse(obj.url)
                    let result = `import requests\n\n\n`
                    result += `url= "${urlObj.url}"\n`
                    result += `params = ${urlObj.params}\n`
                    result += `headers = ${obj.headers}\n`
                    result += `cookies = ${obj.cookies}\n`

                    if (obj.data.length > 3){
                        result += `data = ${obj.data}\n`
                        result += `response = requests.post(url, headers=headers, cookies=cookies, params=params, data=data)\n\n`
                    }
                    else if (obj.data.length > 3){
                        result += `data = ${obj.jsonData}\n`
                        result += `response = requests.post(url, headers=headers, cookies=cookies, params=params, json=data)\n\n`
                    }
                    else {
                        result += `response = requests.get(url, headers=headers, cookies=cookies, params=params)\n\n`
                    }

                    result += `print(response.text)\nprint(response)`
                    this.result = result
                    this.showResult = hljs.highlight(result, {language: 'python'}).value
                }
            } catch (e) {
                console.log(e)
            }
        },

        getCopy() {
            this.$copyText(this.result).then((e) => {
                console.log('【复制成功】', e)
                this.$message({
                    message: '复制成功',
                    type: 'success',
                    center: true
                });

            }, function (e) {
                console.log('【复制失败】', e)
            })

        },

    }
}
</script>


<style>
.code_content {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    height: calc(100vh - 19px);
    border: 1px solid #dcdfe6;
    background-color: #fff;
    border-radius: 4px;
}


.el-input--medium {
    font-size: 14px;
}

.el-textarea {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: bottom;
    font-size: 14px;
}
</style>
