<template>
    <div>
        <el-row :gutter="0">
            <el-col :span="11">
                <el-input
                        type="textarea"
                        class="text-box text-box-input"
                        placeholder="请输入JSON"
                        :rows="inputHeight"
                        @input="getJsonText"
                        v-model="textarea">

                </el-input>
            </el-col>
            <el-col :span="13">
                <div class="code_content">
                    <div class="b-select">
                        <el-button class="text-button operation_btn" type="text" icon="el-icon-d-caret"
                                   @click="jsonClosed=!jsonClosed">折叠
                        </el-button>
                        <el-select v-model="deep" size="mini" placeholder="" class="operation_btn">
                            <el-option
                                    v-for="item in deepOptions"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                            </el-option>
                        </el-select>

                        <el-button class="text-button operation_btn"
                                   type="text"
                                   icon="el-icon-document-copy" @click="getCopy">复制
                        </el-button>
                    </div>
                    <div>
                        <json-view
                                :data="textareaResult"
                                :deep="deep"
                                :closed="jsonClosed"
                                theme="my-awesome-json-theme"
                        >
                        </json-view>
                    </div>
                </div>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import jsonView from 'vue-json-views'

export default {
    components: {
        jsonView,
    },
    data() {
        return {
            deep: 3,
            deepOptions: Array.from({length: 10}, (_, i) => i),
            textarea: '',
            textareaResult: {
                "tip1": "支持python dict",
                "tip2": "支持编辑 key value",
                "tip3": "我包含双引号，\"哈哈哈\"，可一键将我转义与反转义",
                "tip4": "支持显示节点路径，页面底部也可显示当前节点的路径",
                "tip5": "支持从包含json格式的数据中提取json，如直接复制一段包含json的文本，可一键提取json",
                "tip6": "json格式化报错，可直接在报错位置修改，然后Crtl + A全选修改后的json，再次格式化，即可修复报错"
            },
            inputHeight: Math.floor(window.innerHeight / 21),
            jsonString: '',
            jsonClosed: false,
            formattedJson: ''
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    created() {
      this.$axios.get('home/tls').then(res => {
        // console.log(res.data)
        this.textareaResult = res.data
      })
    },
  methods: {
        handleResize() {
            // 在窗口大小变化时触发的操作
            this.inputHeight = Math.floor(window.innerHeight / 22.5)
        },

        getJsonText() {
            try {
                if (this.textarea) {
                    // let textareaResult = JSON.stringify(jsonObject, null, 4)
                    // this.textareaResult = JSON.stringify(jsonObject, null, 4)
                    this.textareaResult = eval(`(${this.textarea})`)
                    // this.textareaResult = hljs.highlight(textareaResult, {language: 'json'}).value
                }
            } catch (e) {
                this.$message({
                    message: e,
                    type: 'error',
                    center: true
                });
            }

        },

        getCopy() {
            this.$copyText(JSON.stringify(this.textareaResult, null, 4)).then((e) => {
                console.log('【复制成功】', e)
                this.$message({
                    message: '复制成功',
                    type: 'success',
                    center: true
                });

            }, function (e) {
                console.log('【复制失败】', e)
            })

        },


    }
}
</script>

<style lang="less">
@import "@/assets/button.css";

.text-box {
  width: 100%; /* 让文本框充满父容器的宽度 */
}


</style>
